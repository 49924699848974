<template>
  <v-container fluid class="ma-0 pa-0 fill-height">
    <v-row dense>
      <v-col cols="3">
        <scheduled-featured-video-card></scheduled-featured-video-card>
      </v-col>
      <v-col cols="9" class="pl-4">
        <featured-video-list></featured-video-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const ScheduledFeaturedVideoCard = () =>
  import("../cards/ScheduledFeaturedVideoCard.vue");
const FeaturedVideoList = () => import("../lists/FeaturedVideoList.vue");

export default {
  components: {
    ScheduledFeaturedVideoCard,
    FeaturedVideoList
  }
};
</script>
